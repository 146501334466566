import { Controller } from "@hotwired/stimulus";
import flatpickr from "flatpickr"
import debounce from "lodash/debounce";
import isVisible from "true-visibility"
import moment from "moment";

export default class extends Controller {
  static values = {
    deviceCountUrl: String,
    updatePreviewUrl: String,
    remediationStrategyPreviewUrl: String,
    remediationStrategyPreviewSearch: String,
    sectionId: String,
  }

  static targets = [
    "menu",
    "message",
    "beginsBlockingOn",
    "warnOnlyFields",
    "defaultWarningLabel",
    "blockingAlreadySetWarningLabel",
    "preview",
    "updatePreview",
    "form",
    "targetsForm",
    "advancedOptions",
    "warnThenBlockGap",
    "remediationStrategy",
    "reportOnlyStrategy",
    "notifyOnlyStrategy",
    "warnThenBlockStrategy",
    "blockImmediatelyStrategy",
    "remediationStrategyPreviewSearchInput"
  ];


  connect() {
    this.isEditing = false;
    document.body.addEventListener("warn-grace-period", this.handleWarningGracePeriod);
    document.body.addEventListener("block-grace-period", this.handleBlockingGracePeriod);
    document.body.addEventListener("notify-only", this.handleNotifyOnlyGracePeriod);
  }

  disconnect() {
    document.body.removeEventListener("warn-grace-period", this.handleWarningGracePeriod);
    document.body.removeEventListener("block-grace-period", this.handleBlockingGracePeriod);
    document.body.removeEventListener("notify-only", this.handleNotifyOnlyGracePeriod);
  }

  handleWarningGracePeriod = debounce((event) => {
    if (this.warningGracePeriod != event.detail) {
      this.warningGracePeriod = event.detail;
      this.fetchRemediationStrategyPreview();
    }
    this.updateWarnThenBlockGap();
  }, 300)

  handleBlockingGracePeriod = debounce((event) => {
    if (this.blockingGracePeriod != event.detail) {
      this.blockingGracePeriod = event.detail;
      this.fetchRemediationStrategyPreview();
    }
    this.updateWarnThenBlockGap();
  }, 300)

  handleNotifyOnlyGracePeriod = debounce((event) => {
    if (this.warningGracePeriod != event.detail) {
      this.warningGracePeriod = event.detail;
      this.fetchRemediationStrategyPreview();
    }
  }, 300)

  updateWarnThenBlockGap() {
    const warn = this.warningGracePeriod || 0;
    const block = this.blockingGracePeriod || 0;
    const gap = Math.abs(block - warn)

    if (gap > 1) {
      this.warnThenBlockGapTarget.innerHTML = `${gap} days`
    } else {
      this.warnThenBlockGapTarget.innerHTML = `${gap} day`
    }
  }

  selectRemediationStrategy(event) {
    this.chooseRemediationStrategy(event.currentTarget.dataset.target);
  }

  strategies() {
    return [
      this.reportOnlyStrategyTarget,
      this.notifyOnlyStrategyTarget,
      this.warnThenBlockStrategyTarget,
      this.blockImmediatelyStrategyTarget
    ]
  }

  chooseRemediationStrategy(strategy) {
    this.chosenRemediationStrategy = strategy;
    this.strategies().forEach((el) => {
      el.classList.remove("active");
      // disable all inputs for all strategies
      // this will ensure that the form is submitted with the correct values as
      // the disabled inputs are not submitted when the form is submitted. We
      // then enable the inputs for each strategy separately as they are chosen
      Array.from(el.querySelectorAll("input")).forEach((input) => {
        input.disabled = true;
      })
    });
    switch (strategy) {
      case "report_only":
        this.reportOnlyStrategyTarget.classList.add("active");
        this.hideAdvancedOptionsAndTargetsForm();
        this.enableInputsForStrategy(this.reportOnlyStrategyTarget);
        break;
      case "notify_only":
        this.notifyOnlyStrategyTarget.classList.add("active");
        this.targetsFormTarget.style.display = "block";
        this.enableInputsForStrategy(this.notifyOnlyStrategyTarget);
        break;
      case "warn_then_block":
        this.warnThenBlockStrategyTarget.classList.add("active");
        this.checkDate();
        this.showAdvancedOptionsAndTargetsForm();
        this.enableInputsForStrategy(this.warnThenBlockStrategyTarget);
        break;
      case "block_immediately":
        this.blockImmediatelyStrategyTarget.classList.add("active");
        this.checkDate();
        this.showAdvancedOptionsAndTargetsForm();
        this.enableInputsForStrategy(this.blockImmediatelyStrategyTarget);
        break;
    }

    // Refetch preview
    this.fetchRemediationStrategyPreview();
  }

  showAdvancedOptionsAndTargetsForm() {
    this.targetsFormTarget.style.display = "block";
    this.advancedOptionsTarget.style.display = "block";
  }

  hideAdvancedOptionsAndTargetsForm() {
    this.targetsFormTarget.style.display = "none";
    this.advancedOptionsTarget.style.display = "none";
  }

  checkDate() {
    const today = moment().startOf('day');
    const chosenDate = moment.utc(this.beginsBlockingOnTargets[0].value, 'YYYY-MM-DD').startOf('day');
    const diff = (today - chosenDate) / 86_400_000; // diff in days
    this.updateWarnOnlyMessages(diff);
  }

  enableInputsForStrategy(strategy) {
    Array.from(strategy.querySelectorAll("input")).forEach((input) => {
      if (input.dataset.originallyDisabled != "true") {
        input.disabled = false;
      }
    })
  }

formatDays(numDays) {
  if (numDays === 1) {
    return `${numDays} day in the past`;
  } else if (numDays === 0) {
    return "today";
  } else {
    return `${numDays} days in the past`;
  }
};

updateWarnOnlyMessages(diff) {
  if (diff > 0) {
    const innerContent = `<em>The provided date is <strong>${this.formatDays(Math.round(diff))}.</strong><br/>If you wish to use Warn Only mode, choose a new date in the past.</em>`;
    this.messageTargets.forEach((t) => {
      t.innerHTML = innerContent;
      t.classList.remove("hidden");
    });
    this.beginsBlockingOnTargets.forEach((input) => { input.classList.add("warn-only-past") });
  } else {
    this.beginsBlockingOnTargets.forEach((input) => { input.classList.remove("warn-only-past") });
    this.messageTargets.forEach((t) => { t.classList.add("hidden") });
  }
}

formTargetConnected() {
  this.beginsBlockingOnTargets.forEach((el) => {
    flatpickr(el, {
      dateFormat: 'Y-m-d',
      allowInput: true,
      onChange: (selectedDates) => {
        if (selectedDates.length > 0) {
          const chosenDate = selectedDates[0];
          const today = new Date();
          today.setHours(0, 0, 0, 0); // Reset time for accurate comparison
          const diff = (today - chosenDate) / 86_400_000; // diff in days
          this.updateWarnOnlyMessages(diff);
        }
        this.fetchRemediationStrategyPreview();
      },
    });
  });

  if (this.isEditing) {
    this.formTarget.style.display = "block";
    this.previewTarget.style.display = "none";
    const selectedStrategy = this.remediationStrategyTargets.find((el) => el.checked);
    this.chooseRemediationStrategy(selectedStrategy.dataset.target);
  }
}

  fetchRemediationStrategyPreview() {
    // Collect values
    let data = new FormData(this.formTarget);

    const remediationStrategy = data.get('check[configuration_attributes][remediation_strategy]');
    const notifyGracePeriod = data.get('check[configuration_attributes][notify_auth_grace_period_days]');
    const blockingGracePeriod = data.get('check[configuration_attributes][block_auth_grace_period_days]');
    const beginsBlockingOn = data.getAll('check[configuration_attributes][begins_blocking_on]').find(value => typeof value === 'string' && value !== '');
    const customizedRemediationTargeting = data.getAll('check[configuration_attributes][customized_remediation_targeting]').find(value => typeof value === 'string' && value !== '0') || '0';
    const blockTargetsTagifyJSON =  data.get('check[configuration_attributes][block_targets_tagify_json]');
    const excludedBlockTargetsTagifyJSON =  data.get('check[configuration_attributes][excluded_block_targets_tagify_json]');

    // Build URL parameters conditionally
    let params = new URLSearchParams();

    params.append('remediation_strategy', remediationStrategy);
    params.append('begins_blocking_on', beginsBlockingOn);
    params.append('customized_remediation_targeting', customizedRemediationTargeting);
    params.append('block_targets_tagify_json', this.cleanTagifyStringifiedJSON(blockTargetsTagifyJSON));
    params.append('excluded_block_targets_tagify_json', this.cleanTagifyStringifiedJSON(excludedBlockTargetsTagifyJSON));

    // These fields are sometimes missing from the form depending on
    // the remediation strategy. Their absence should be respected in
    // the preview logic.
    if (notifyGracePeriod) {
        params.append('notify_grace_period', notifyGracePeriod);
    }
    if (blockingGracePeriod) {
        params.append('blocking_grace_period', blockingGracePeriod);
    }

    if (this.remediationStrategyPreviewSearchValue) {
      params.append('search[value]', this.remediationStrategyPreviewSearchValue);
    }

    // Extract the 'nav' parameter from the original src
    if (this.updatePreviewTarget?.src) {
      let originalSrc = this.updatePreviewTarget.src;
      let originalParams = new URLSearchParams(originalSrc.split('?')[1]);
      let navParam = originalParams.get('nav');
      if (navParam) {
        params.append('nav', navParam);
      }
    }

    // Update the preview target
    this.updatePreviewTarget.src = null;
    this.updatePreviewTarget.src = `${this.updatePreviewUrlValue}?${params.toString()}`;
    this.updatePreviewTarget.style.display = "";
  }

  cleanTagifyStringifiedJSON(stringJSON) {
    try {
      let parsedJSON = JSON.parse(stringJSON);
      let simplifiedJSON = parsedJSON.map(item => ({ value: item.value }));
      return JSON.stringify(simplifiedJSON);
    } catch (error) {
      return stringJSON;
    }
  }

  toggleBeginsBlockingOn(e) {
    this.beginsBlockingOnTargets.forEach((el) => {
      if(isVisible(el)) {
        el.disabled = !e.target.checked;
      }
    });
  }

  recordSearchTerm() {
    this.remediationStrategyPreviewSearchValue = this.remediationStrategyPreviewSearchInputTarget.value;
  }

  editConfig(e) {
    e.preventDefault();
    this.isEditing = true;

    fetch(e.currentTarget.href)
      .then(response => response.text())
      .then(text => {
        Turbo.renderStreamMessage(text)
        // Send custom event 'config-edit-open' to document.body
        // This is used to trigger events after the edit pane is opened
        const event = new CustomEvent('config-edit-open');
        document.body.dispatchEvent(event);
      })
  }

  cancelEditing(e) {
    e.preventDefault();
    this.isEditing = false;
    this.formTarget.style.display = "none"
    this.previewTarget.style.display = "block"
  }
}
