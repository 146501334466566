import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['autoUpdated'];

  update(e) {
    const percentage = e.target.value;
    this.autoUpdatedTarget.value = 100.0 - percentage;
  }
}
