import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    requestId: String
  }

  connect() {
    let parentLocation = window.location.ancestorOrigins[0];
    let iframeLocation = window.location.origin;

    // leaving these in for debugging purposes...
    console.info(`Parent: ${parentLocation}`);
    console.info(`iframe: ${iframeLocation}`);

    // we're in an iFrame
    if (parentLocation !== undefined && (parentLocation !== iframeLocation)) {
      let origin = encodeURIComponent(parentLocation);
      window.location = `/device/iframe_entry?request_id=${this.requestIdValue}&origin=${origin}`;
    }
  }
}
